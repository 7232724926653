
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { GetServerSideProps } from 'next';
import { NextSeo } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { handleError } from '@/helpers/handleError';
import Footer from '@/components/Footer';
import HeaderMenu from '@/components/HeaderMenu';
import BannerMainPage from '@/components/MainPage/BannerMainPage';
import { GET_CATEGORY_POSTS, GET_FOOTER_DATA, GET_INDEX_DATA } from '@/graphql/queries';
import { GET_HEADER_DATA } from '@/graphql/headerQueries';
import { ONE_MINUTE_IN_SECONDS, TEN_SECONDS } from '@/constants/timeConstants';
import { addApolloState, initializeApollo } from '@/lib/apolloClient';
import { GET_COOKIES } from '@/graphql/globalQueries';
export default function Home({ cookies, header, navbar, homepage, latest, footer }: any) {
    const MultiComponentAnimated = dynamic(() => import('@/components/MainPage/MultiComponentAnimated'), {
        ssr: false
    });
    const ClientsComponent = dynamic(() => import('@/components/MainPage/ClientsComponent'), {
        ssr: false
    });
    const OurServices = dynamic(() => import('@/components/MainPage/OurServices'), {
        ssr: false
    });
    const OurBlog = dynamic(() => import('@/components/MainPage/OurBlog'), { ssr: false });
    const Differentials = dynamic(() => import('@/components/MainPage/Differentials'), {
        ssr: false
    });
    const FocusOnTechnology = dynamic(() => import('@/components/MainPage/FocusOnTechnology'), {
        ssr: false
    });
    const { t } = useTranslation('common');
    // const testimoniesWithClients =
    //   homepage.data.attributes.clients.data.flatMap((client: any) =>
    //     client.attributes.testimony.map((testimony: any) => ({
    //       testimony: testimony,
    //       client: client,
    //     }))
    //   );
    return (<>
      <HeaderMenu cookies={cookies.data} menu={header[0].items} submenu={navbar.data?.attributes.menu_header_list}/>
      <NextSeo title={homepage.data.attributes.metaTitle} description={homepage.data.attributes.metaDescription}/>
      <BannerMainPage title={t('homepage.banner.title')} description={t('homepage.banner.description')}/>
      <ClientsComponent clients={homepage.data.attributes.clients.data}/>
      <OurServices />
      <MultiComponentAnimated items={[
            {
                title: `${t('homepage.howWeDo.v1.title1')}`,
                description: `${t('homepage.howWeDo.v1.description1')}`
            },
            {
                title: `${t('homepage.howWeDo.v1.title2')}`,
                description: `${t('homepage.howWeDo.v1.description2')}`
            },
            {
                title: `${t('homepage.howWeDo.v1.title3')}`,
                description: `${t('homepage.howWeDo.v1.description3')}`
            },
        ]}/>
      {/* {testimoniesWithClients.length > 0 && (
          <ProjectsWithResults
            clients={homepage.data.attributes.clients.data}
          />
        )} */}
      <OurBlog posts={latest.data}/>
      <Differentials />
      <FocusOnTechnology />
      <Footer menu={footer[0].items} clients={homepage.data.attributes.clients.data}/>
    </>);
}
const getServerSideProps: GetServerSideProps = async ({ res }) => {
    try {
        res.setHeader('Cache-Control', `public, s-maxage=${TEN_SECONDS}, stale-while-revalidate=${ONE_MINUTE_IN_SECONDS}`);
        const client = initializeApollo();
        const { data: homepageData } = await client.query({
            query: GET_INDEX_DATA
        });
        const { data: categoryArticlesData } = await client.query({
            query: GET_CATEGORY_POSTS
        });
        const { data: headerData } = await client.query({
            query: GET_HEADER_DATA
        });
        const { data: footerData } = await client.query({
            query: GET_FOOTER_DATA
        });
        const { data: cookiesData } = await client.query({
            query: GET_COOKIES
        });
        return addApolloState(client, {
            props: {
                ...footerData,
                ...cookiesData,
                ...homepageData,
                ...categoryArticlesData,
                ...headerData
            }
        });
    }
    catch (error) {
        handleError(error, 'Index getServerSideProps');
        return {
            notFound: true
        };
    }
};

    async function __Next_Translate__getServerSideProps__19195e0b04a__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: typeof __i18nConfig.loadLocaleFrom === 'function' ? __i18nConfig.loadLocaleFrom : (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__19195e0b04a__ as getServerSideProps }
  